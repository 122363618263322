export default (data = {}) => {
    return {
        AdditionalAccountID: null,
        AddressLine: null,
        CityName: '',
        Country_IdentificationCode: null,
        Department: null,
        PartyIdentification: '222222222222',
        PartyName: 'CONSUMIDOR FINAL',
        PartyEmail: null,
        TaxLevelCode: null,
        TaxTypeCode: null,
        client_birthday: null,
        client_email: null,
        client_phone: null,
        countryEntity: null,
        countrySubentity: null,
        puntosId: null,
        sex: null,
        typeid: "3",
        ...data
    }
}