export default (data = {}) => {
    return {
        PartyIdentification: null,
        typeid: null,
        PartyName: null,
        PartyIdentification_Id: null,
        AddressLine: null,
        CityName: null,
        Department: null,
        Country_IdentificationCode: null,
        client_email: null,
        client_phone: null,
        TaxTypeCode: null,
        TaxLevelCode: null,
        store_code: null,
        type_organization_id: null,
        type_liability_id: null,
        type_regime_id: null,
        environment: 2,
        sync_code: true,
        ...data
    }
}