import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);




const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#635BFF', // Un azul similar al de Stripe
        secondary: '#424770', // Un gris oscuro
        accent: '#24b47e', // Verde para botones o llamadas a la acción
        error: '#ff5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        anchor: '#635BFF',
        background: '#F6F9FC', // Fondo claro usado en Stripe
      },
    },
  },
});

export default vuetify;
