import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import VueSignaturePad from 'vue-signature-pad';
import './registerServiceWorker'
Vue.use(VueSignaturePad);
Vue.config.productionTip = false






new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')