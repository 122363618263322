export default (data = {}) => {
    return {
        brand: null,
        cantidad: null,
        categoria: null,
        category: null,
        color: null,
        colorName: null,
        costo: null,
        descuento: null,
        discount: null,
        ean: null,
        groupName: null,
        grupo: null,
        id_pos: null,
        marca: null,
        precio: null,
        precioB: null,
        precioC: null,
        qty: null,
        refe: null,
        sku: null,
        store_name: null,
        talla: null,
        ...data
    }
}