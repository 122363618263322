import Vuex from 'vuex'
import Vue from 'vue'

const defaultState = () => {
    return {
        profile: {
            store_id: null,
            token: null,
            token_active: null,
            user_access: null,
            user_email: null,
            user_name: null,
            user_password: null,
            user_photo: null,
        },
        seller: {
            PartyIdentification_Id: null,
            PartyName: null,
            TaxLevelCode: null,
            codigo: null,
            hours: null,
            regional: null,
            ruta: null,
            store_Caja: null,
            store_CentroCosto: null,
            store_address: null,
            store_business_id: null,
            store_category: null,
            store_city: null,
            store_days: null,
            store_email: null,
            store_footer: null,
            store_header: null,
            store_id: null,
            store_invoice: null,
            store_logo: null,
            store_name: null,
            store_owner: null,
            store_phone: null,
            store_prefix: null,
            store_target: null,
            store_type: null,
            store_url: null,
            zona: null,
        },
        menuStatus: false,
        navStatus: false,
    }
}

Vue.use(Vuex)

export default new Vuex.Store({
    state: defaultState(),
    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
        },
        setSeller(state, seller) {
            state.seller = seller;
        },
    },
    actions: {
        setProfile(context, profile) {
            context.commit("setProfile", profile)
        },
        setSeller(context, value) {
            context.commit("setSeller", value)
        },
        setNavStatus(context, value) {
            context.state.navStatus = value;
        },
        set_menu(context, value) {
            context.state.menuStatus = value;
        },
    },
    getters: {
        profileUserid(state) {
            return state.profile.userid
        },
        profile(state) {
            return state.profile
        },
        seller(state) {
            return state.seller
        },
        profileName(state) {
            return state.profile.name
        },
        profilePicture(state) {
            return state.profile.picture
        },
        getMenuStatus: state => state.menuStatus,
        getNavStatus: state => state.navStatus,
    }
})