export default (data = {}) => {
    return {
      "type_liabilities": [
        {
          "id": "5",
          "name": "Gran contribuyente",
          "code": "O-13",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "7",
          "name": "Autorretenedor",
          "code": "O-15",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "12",
          "name": "Agente de retención IVA",
          "code": "O-23",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "20",
          "name": "Régimen simple de tributación",
          "code": "O-47",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "29",
          "name": "No aplica – Otros",
          "code": "R-99-PN",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        }
      ],
      "type_organizations": [
        {
          "id": "1",
          "name": "Persona Jurídica",
          "code": "1",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:48.000000Z",
          "updated_at": "2023-04-13T22:35:48.000000Z"
        },
        {
          "id": "2",
          "name": "Persona Natural",
          "code": "2",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:48.000000Z",
          "updated_at": "2023-04-13T22:35:48.000000Z"
        }
      ],
      "type_regimes": [
        {
          "id": "1",
          "name": "Impuesto sobre las ventas – IVA",
          "code": "48",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "2",
          "name": "No responsable de IVA",
          "code": "49",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        }
      ],
      "type_document_identifications": [
        {
          "id": "1",
          "name": "Registro civil",
          "scope": null,
          "code": "11",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "2",
          "name": "Tarjeta de identidad",
          "scope": null,
          "code": "12",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "3",
          "name": "Cédula de ciudadanía",
          "scope": null,
          "code": "13",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "4",
          "name": "Tarjeta de extranjería",
          "scope": null,
          "code": "21",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "5",
          "name": "Cédula de extranjería",
          "scope": null,
          "code": "22",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "6",
          "name": "NIT",
          "scope": null,
          "code": "31",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "7",
          "name": "Pasaporte",
          "scope": null,
          "code": "41",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "8",
          "name": "Documento de identificación extranjero",
          "scope": null,
          "code": "42",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "9",
          "name": "NIT de otro país",
          "scope": null,
          "code": "50",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "10",
          "name": "NUIP",
          "scope": null,
          "code": "91",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "11",
          "name": "Cédula de ciudadanía",
          "scope": "health",
          "code": "CC",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "12",
          "name": "Cédula de extranjería",
          "scope": "health",
          "code": "CE",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "13",
          "name": "Carné diplomático",
          "scope": "health",
          "code": "CD",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "14",
          "name": "Pasaporte",
          "scope": "health",
          "code": "PA",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "15",
          "name": "Salvoconducto",
          "scope": "health",
          "code": "SC",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "16",
          "name": "Permiso especial de permanencia",
          "scope": "health",
          "code": "PE",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "17",
          "name": "Registro civil de nacimiento",
          "scope": "health",
          "code": "RC",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "18",
          "name": "Tarjeta de identidad",
          "scope": "health",
          "code": "TI",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "19",
          "name": "Certificado de nacido vivo",
          "scope": "health",
          "code": "CN",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "20",
          "name": "Adulto sin identificar",
          "scope": "health",
          "code": "AS",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "21",
          "name": "Menor sin identificar",
          "scope": "health",
          "code": "MS",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "22",
          "name": "Documento extranjero",
          "scope": "health",
          "code": "DE",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "23",
          "name": "Sin identificación",
          "scope": "health",
          "code": "SI",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "24",
          "name": "PEP",
          "scope": null,
          "code": "47",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        {
          "id": "25",
          "name": "Certificado Registraduría sin identificación",
          "scope": "reception",
          "code": "10910036",
          "deleted_at": null,
          "created_at": "2023-04-13T22:35:49.000000Z",
          "updated_at": "2023-04-13T22:35:49.000000Z"
        },
        
      ],
      "municipalities" : [
          {
              "tableName": "municipalities",
              "id": "1",
              "code": "05001",
              "name": "Medellín",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
          },
          {
              "tableName": "municipalities",
              "id": "2",
              "code": "05002",
              "name": "Abejorral",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
          },
          {
              "tableName": "municipalities",
              "id": "3",
              "code": "05004",
              "name": "Abriaquí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "4",
              "code": "05021",
              "name": "Alejandría",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "5",
              "code": "05030",
              "name": "Amagá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "6",
              "code": "05031",
              "name": "Amalfi",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "7",
              "code": "05034",
              "name": "Andes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "8",
              "code": "05036",
              "name": "Angelópolis",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "9",
              "code": "05038",
              "name": "Angostura",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "10",
              "code": "05040",
              "name": "Anorí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "11",
              "code": "05042",
              "name": "Santa Fé De Antioquia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "12",
              "code": "05044",
              "name": "Anzá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "13",
              "code": "05045",
              "name": "Apartadó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "14",
              "code": "05051",
              "name": "Arboletes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "15",
              "code": "05055",
              "name": "Argelia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "16",
              "code": "05059",
              "name": "Armenia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "17",
              "code": "05079",
              "name": "Barbosa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "18",
              "code": "05086",
              "name": "Belmira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "19",
              "code": "05088",
              "name": "Bello",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "20",
              "code": "05091",
              "name": "Betania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "21",
              "code": "05093",
              "name": "Betulia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "22",
              "code": "05101",
              "name": "Ciudad Bolívar ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "23",
              "code": "05107",
              "name": "Briceño",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "24",
              "code": "05113",
              "name": "Buriticá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "25",
              "code": "05120",
              "name": "Cáceres",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "26",
              "code": "05125",
              "name": "Caicedo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "27",
              "code": "05129",
              "name": "Caldas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "28",
              "code": "05134",
              "name": "Campamento",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "29",
              "code": "05138",
              "name": "Cañasgordas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "30",
              "code": "05142",
              "name": "Caracolí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "31",
              "code": "05145",
              "name": "Caramanta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "32",
              "code": "05147",
              "name": "Carepa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "33",
              "code": "05148",
              "name": "El Carmen De Viboral",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "34",
              "code": "05150",
              "name": "Carolina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "35",
              "code": "05154",
              "name": "Caucasia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "36",
              "code": "05172",
              "name": "Chigorodó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "37",
              "code": "05190",
              "name": "Cisneros",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "38",
              "code": "05197",
              "name": "Cocorná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "39",
              "code": "05206",
              "name": "Concepción",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "40",
              "code": "05209",
              "name": "Concordia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "41",
              "code": "05212",
              "name": "Copacabana",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "42",
              "code": "05234",
              "name": "Dabeiba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "43",
              "code": "05237",
              "name": "Donmatías",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "44",
              "code": "05240",
              "name": "Ebéjico",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "45",
              "code": "05250",
              "name": "El Bagre ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "46",
              "code": "05264",
              "name": "Entrerríos",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "47",
              "code": "05266",
              "name": "Envigado",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "48",
              "code": "05282",
              "name": "Fredonia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "49",
              "code": "05284",
              "name": "Frontino",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "50",
              "code": "05306",
              "name": "Giraldo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "51",
              "code": "05308",
              "name": "Girardota",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "52",
              "code": "05310",
              "name": "Gómez Plata ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "53",
              "code": "05313",
              "name": "Granada",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "54",
              "code": "05315",
              "name": "Guadalupe",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "55",
              "code": "05318",
              "name": "Guarne",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "56",
              "code": "05321",
              "name": "Guatapé",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "57",
              "code": "05347",
              "name": "Heliconia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "58",
              "code": "05353",
              "name": "Hispania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "59",
              "code": "05360",
              "name": "Itagüí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "60",
              "code": "05361",
              "name": "Ituango",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "61",
              "code": "05364",
              "name": "Jardín",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "62",
              "code": "05368",
              "name": "Jericó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "63",
              "code": "05376",
              "name": "La Ceja ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "64",
              "code": "05380",
              "name": "La Estrella ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "65",
              "code": "05390",
              "name": "La Pintada ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "66",
              "code": "05400",
              "name": "La Unión ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "67",
              "code": "05411",
              "name": "Liborina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "68",
              "code": "05425",
              "name": "Maceo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "69",
              "code": "05440",
              "name": "Marinilla",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "70",
              "code": "05467",
              "name": "Montebello",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "71",
              "code": "05475",
              "name": "Murindó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "72",
              "code": "05480",
              "name": "Mutatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "73",
              "code": "05483",
              "name": "Nariño",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "74",
              "code": "05490",
              "name": "Necoclí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "75",
              "code": "05495",
              "name": "Nechí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "76",
              "code": "05501",
              "name": "Olaya",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "77",
              "code": "05541",
              "name": "Peñol",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "78",
              "code": "05543",
              "name": "Peque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "79",
              "code": "05576",
              "name": "Pueblorrico",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "80",
              "code": "05579",
              "name": "Puerto Berrío ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "81",
              "code": "05585",
              "name": "Puerto Nare ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "82",
              "code": "05591",
              "name": "Puerto Triunfo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "83",
              "code": "05604",
              "name": "Remedios",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "84",
              "code": "05607",
              "name": "Retiro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "85",
              "code": "05615",
              "name": "Rionegro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "86",
              "code": "05628",
              "name": "Sabanalarga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "87",
              "code": "05631",
              "name": "Sabaneta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "88",
              "code": "05642",
              "name": "Salgar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "89",
              "code": "05647",
              "name": "San Andrés De Cuerquía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "90",
              "code": "05649",
              "name": "San Carlos ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "91",
              "code": "05652",
              "name": "San Francisco ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "92",
              "code": "05656",
              "name": "San Jerónimo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "93",
              "code": "05658",
              "name": "San José De La Montaña ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "94",
              "code": "05659",
              "name": "San Juan De Urabá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "95",
              "code": "05660",
              "name": "San Luis ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "96",
              "code": "05664",
              "name": "San Pedro De Los Milagros ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "97",
              "code": "05665",
              "name": "San Pedro De Urabá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "98",
              "code": "05667",
              "name": "San Rafael ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "99",
              "code": "05670",
              "name": "San Roque ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "100",
              "code": "05674",
              "name": "San Vicente Ferrer",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "101",
              "code": "05679",
              "name": "Santa Bárbara ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "102",
              "code": "05686",
              "name": "Santa Rosa De Osos",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "103",
              "code": "05690",
              "name": "Santo Domingo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "104",
              "code": "05697",
              "name": "El Santuario ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "105",
              "code": "05736",
              "name": "Segovia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "106",
              "code": "05756",
              "name": "Sonsón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "107",
              "code": "05761",
              "name": "Sopetrán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "108",
              "code": "05789",
              "name": "Támesis",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "109",
              "code": "05790",
              "name": "Tarazá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "110",
              "code": "05792",
              "name": "Tarso",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "111",
              "code": "05809",
              "name": "Titiribí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "112",
              "code": "05819",
              "name": "Toledo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "113",
              "code": "05837",
              "name": "Turbo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "114",
              "code": "05842",
              "name": "Uramita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "115",
              "code": "05847",
              "name": "Urrao",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "116",
              "code": "05854",
              "name": "Valdivia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "117",
              "code": "05856",
              "name": "Valparaíso",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "118",
              "code": "05858",
              "name": "Vegachí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "119",
              "code": "05861",
              "name": "Venecia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "120",
              "code": "05873",
              "name": "Vigía Del Fuerte",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "121",
              "code": "05885",
              "name": "Yalí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "122",
              "code": "05887",
              "name": "Yarumal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "123",
              "code": "05890",
              "name": "Yolombó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "124",
              "code": "05893",
              "name": "Yondó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "125",
              "code": "05895",
              "name": "Zaragoza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "126",
              "code": "08001",
              "name": "Barranquilla",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "127",
              "code": "08078",
              "name": "Baranoa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "128",
              "code": "08137",
              "name": "Campo De La Cruz",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "129",
              "code": "08141",
              "name": "Candelaria",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "130",
              "code": "08296",
              "name": "Galapa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "131",
              "code": "08372",
              "name": "Juan De Acosta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "132",
              "code": "08421",
              "name": "Luruaco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "133",
              "code": "08433",
              "name": "Malambo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "134",
              "code": "08436",
              "name": "Manatí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "135",
              "code": "08520",
              "name": "Palmar De Varela",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "136",
              "code": "08549",
              "name": "Piojó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "137",
              "code": "08558",
              "name": "Polonuevo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "138",
              "code": "08560",
              "name": "Ponedera",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "139",
              "code": "08573",
              "name": "Puerto Colombia ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "140",
              "code": "08606",
              "name": "Repelón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "141",
              "code": "08634",
              "name": "Sabanagrande",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "142",
              "code": "08638",
              "name": "Sabanalarga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "143",
              "code": "08675",
              "name": "Santa Lucía ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "144",
              "code": "08685",
              "name": "Santo Tomás ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "145",
              "code": "08758",
              "name": "Soledad",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "146",
              "code": "08770",
              "name": "Suan",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "147",
              "code": "08832",
              "name": "Tubará",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "148",
              "code": "08849",
              "name": "Usiacurí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "149",
              "code": "11001",
              "name": "Bogotá, D.C. ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "150",
              "code": "13001",
              "name": "Cartagena De Indias",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "151",
              "code": "13006",
              "name": "Achí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "152",
              "code": "13030",
              "name": "Altos Del Rosario",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "153",
              "code": "13042",
              "name": "Arenal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "154",
              "code": "13052",
              "name": "Arjona",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "155",
              "code": "13062",
              "name": "Arroyohondo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "156",
              "code": "13074",
              "name": "Barranco De Loba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "157",
              "code": "13140",
              "name": "Calamar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "158",
              "code": "13160",
              "name": "Cantagallo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "159",
              "code": "13188",
              "name": "Cicuco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "160",
              "code": "13212",
              "name": "Córdoba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "161",
              "code": "13222",
              "name": "Clemencia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "162",
              "code": "13244",
              "name": "El Carmen De Bolívar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "163",
              "code": "13248",
              "name": "El Guamo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "164",
              "code": "13268",
              "name": "El Peñón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "165",
              "code": "13300",
              "name": "Hatillo De Loba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "166",
              "code": "13430",
              "name": "Magangué",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "167",
              "code": "13433",
              "name": "Mahates",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "168",
              "code": "13440",
              "name": "Margarita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "169",
              "code": "13442",
              "name": "María La Baja",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "170",
              "code": "13458",
              "name": "Montecristo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "171",
              "code": "13468",
              "name": "Mompós",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "172",
              "code": "13473",
              "name": "Morales",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "173",
              "code": "13490",
              "name": "Norosí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "174",
              "code": "13549",
              "name": "Pinillos",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "175",
              "code": "13580",
              "name": "Regidor",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "176",
              "code": "13600",
              "name": "Río Viejo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "177",
              "code": "13620",
              "name": "San Cristóbal ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "178",
              "code": "13647",
              "name": "San Estanislao ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "179",
              "code": "13650",
              "name": "San Fernando ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "180",
              "code": "13654",
              "name": "San Jacinto ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "181",
              "code": "13655",
              "name": "San Jacinto Del Cauca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "182",
              "code": "13657",
              "name": "San Juan Nepomuceno",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "183",
              "code": "13667",
              "name": "San Martín De Loba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "184",
              "code": "13670",
              "name": "San Pablo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "185",
              "code": "13673",
              "name": "Santa Catalina ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "186",
              "code": "13683",
              "name": "Santa Rosa ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "187",
              "code": "13688",
              "name": "Santa Rosa Del Sur",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "188",
              "code": "13744",
              "name": "Simití",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "189",
              "code": "13760",
              "name": "Soplaviento",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "190",
              "code": "13780",
              "name": "Talaigua Nuevo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "191",
              "code": "13810",
              "name": "Tiquisio",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "192",
              "code": "13836",
              "name": "Turbaco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "193",
              "code": "13838",
              "name": "Turbaná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "194",
              "code": "13873",
              "name": "Villanueva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "195",
              "code": "13894",
              "name": "Zambrano",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "196",
              "code": "15001",
              "name": "Tunja",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "197",
              "code": "15022",
              "name": "Almeida",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "198",
              "code": "15047",
              "name": "Aquitania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "199",
              "code": "15051",
              "name": "Arcabuco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "200",
              "code": "15087",
              "name": "Belén",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "201",
              "code": "15090",
              "name": "Berbeo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "202",
              "code": "15092",
              "name": "Betéitiva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "203",
              "code": "15097",
              "name": "Boavita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "204",
              "code": "15104",
              "name": "Boyacá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "205",
              "code": "15106",
              "name": "Briceño",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "206",
              "code": "15109",
              "name": "Buenavista",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "207",
              "code": "15114",
              "name": "Busbanzá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "208",
              "code": "15131",
              "name": "Caldas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "209",
              "code": "15135",
              "name": "Campohermoso",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "210",
              "code": "15162",
              "name": "Cerinza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "211",
              "code": "15172",
              "name": "Chinavita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "212",
              "code": "15176",
              "name": "Chiquinquirá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "213",
              "code": "15180",
              "name": "Chiscas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "214",
              "code": "15183",
              "name": "Chita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "215",
              "code": "15185",
              "name": "Chitaraque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "216",
              "code": "15187",
              "name": "Chivatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "217",
              "code": "15189",
              "name": "Ciénega",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "218",
              "code": "15204",
              "name": "Cómbita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "219",
              "code": "15212",
              "name": "Coper",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "220",
              "code": "15215",
              "name": "Corrales",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "221",
              "code": "15218",
              "name": "Covarachía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "222",
              "code": "15223",
              "name": "Cubará",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "223",
              "code": "15224",
              "name": "Cucaita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "224",
              "code": "15226",
              "name": "Cuítiva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "225",
              "code": "15232",
              "name": "Chíquiza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "226",
              "code": "15236",
              "name": "Chivor",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "227",
              "code": "15238",
              "name": "Duitama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "228",
              "code": "15244",
              "name": "El Cocuy ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "229",
              "code": "15248",
              "name": "El Espino ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "230",
              "code": "15272",
              "name": "Firavitoba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "231",
              "code": "15276",
              "name": "Floresta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "232",
              "code": "15293",
              "name": "Gachantivá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "233",
              "code": "15296",
              "name": "Gámeza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "234",
              "code": "15299",
              "name": "Garagoa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "235",
              "code": "15317",
              "name": "Guacamayas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "236",
              "code": "15322",
              "name": "Guateque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "237",
              "code": "15325",
              "name": "Guayatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "238",
              "code": "15332",
              "name": "Güicán De La Sierra",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "239",
              "code": "15362",
              "name": "Iza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "240",
              "code": "15367",
              "name": "Jenesano",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "241",
              "code": "15368",
              "name": "Jericó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "242",
              "code": "15377",
              "name": "Labranzagrande",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "243",
              "code": "15380",
              "name": "La Capilla ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "244",
              "code": "15401",
              "name": "La Victoria ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "245",
              "code": "15403",
              "name": "La Uvita ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "246",
              "code": "15407",
              "name": "Villa De Leyva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "247",
              "code": "15425",
              "name": "Macanal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "248",
              "code": "15442",
              "name": "Maripí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "249",
              "code": "15455",
              "name": "Miraflores",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "250",
              "code": "15464",
              "name": "Mongua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "251",
              "code": "15466",
              "name": "Monguí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "252",
              "code": "15469",
              "name": "Moniquirá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "253",
              "code": "15476",
              "name": "Motavita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "254",
              "code": "15480",
              "name": "Muzo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "255",
              "code": "15491",
              "name": "Nobsa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "256",
              "code": "15494",
              "name": "Nuevo Colón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "257",
              "code": "15500",
              "name": "Oicatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "258",
              "code": "15507",
              "name": "Otanche",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "259",
              "code": "15511",
              "name": "Pachavita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "260",
              "code": "15514",
              "name": "Páez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "261",
              "code": "15516",
              "name": "Paipa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "262",
              "code": "15518",
              "name": "Pajarito",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "263",
              "code": "15522",
              "name": "Panqueba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "264",
              "code": "15531",
              "name": "Pauna",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "265",
              "code": "15533",
              "name": "Paya",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "266",
              "code": "15537",
              "name": "Paz De Río",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "267",
              "code": "15542",
              "name": "Pesca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "268",
              "code": "15550",
              "name": "Pisba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "269",
              "code": "15572",
              "name": "Puerto Boyacá ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "270",
              "code": "15580",
              "name": "Quípama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "271",
              "code": "15599",
              "name": "Ramiriquí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "272",
              "code": "15600",
              "name": "Ráquira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "273",
              "code": "15621",
              "name": "Rondón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "274",
              "code": "15632",
              "name": "Saboyá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "275",
              "code": "15638",
              "name": "Sáchica",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "276",
              "code": "15646",
              "name": "Samacá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "277",
              "code": "15660",
              "name": "San Eduardo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "278",
              "code": "15664",
              "name": "San José De Pare",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "279",
              "code": "15667",
              "name": "San Luis De Gaceno",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "280",
              "code": "15673",
              "name": "San Mateo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "281",
              "code": "15676",
              "name": "San Miguel De Sema",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "282",
              "code": "15681",
              "name": "San Pablo De Borbur",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "283",
              "code": "15686",
              "name": "Santana",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "284",
              "code": "15690",
              "name": "Santa María ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "285",
              "code": "15693",
              "name": "Santa Rosa De Viterbo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "286",
              "code": "15696",
              "name": "Santa Sofía ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "287",
              "code": "15720",
              "name": "Sativanorte",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "288",
              "code": "15723",
              "name": "Sativasur",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "289",
              "code": "15740",
              "name": "Siachoque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "290",
              "code": "15753",
              "name": "Soatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "291",
              "code": "15755",
              "name": "Socotá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "292",
              "code": "15757",
              "name": "Socha",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "293",
              "code": "15759",
              "name": "Sogamoso",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "294",
              "code": "15761",
              "name": "Somondoco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "295",
              "code": "15762",
              "name": "Sora",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "296",
              "code": "15763",
              "name": "Sotaquirá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "297",
              "code": "15764",
              "name": "Soracá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "298",
              "code": "15774",
              "name": "Susacón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "299",
              "code": "15776",
              "name": "Sutamarchán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "300",
              "code": "15778",
              "name": "Sutatenza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "301",
              "code": "15790",
              "name": "Tasco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "302",
              "code": "15798",
              "name": "Tenza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "303",
              "code": "15804",
              "name": "Tibaná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "304",
              "code": "15806",
              "name": "Tibasosa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "305",
              "code": "15808",
              "name": "Tinjacá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "306",
              "code": "15810",
              "name": "Tipacoque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "307",
              "code": "15814",
              "name": "Toca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "308",
              "code": "15816",
              "name": "Togüí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "309",
              "code": "15820",
              "name": "Tópaga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "310",
              "code": "15822",
              "name": "Tota",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "311",
              "code": "15832",
              "name": "Tununguá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "312",
              "code": "15835",
              "name": "Turmequé",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "313",
              "code": "15837",
              "name": "Tuta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "314",
              "code": "15839",
              "name": "Tutazá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "315",
              "code": "15842",
              "name": "Úmbita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "316",
              "code": "15861",
              "name": "Ventaquemada",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "317",
              "code": "15879",
              "name": "Viracachá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "318",
              "code": "15897",
              "name": "Zetaquira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "319",
              "code": "17001",
              "name": "Manizales",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "320",
              "code": "17013",
              "name": "Aguadas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "321",
              "code": "17042",
              "name": "Anserma",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "322",
              "code": "17050",
              "name": "Aranzazu",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "323",
              "code": "17088",
              "name": "Belalcázar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "324",
              "code": "17174",
              "name": "Chinchiná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "325",
              "code": "17272",
              "name": "Filadelfia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "326",
              "code": "17380",
              "name": "La Dorada ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "327",
              "code": "17388",
              "name": "La Merced ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "328",
              "code": "17433",
              "name": "Manzanares",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "329",
              "code": "17442",
              "name": "Marmato",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "330",
              "code": "17444",
              "name": "Marquetalia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "331",
              "code": "17446",
              "name": "Marulanda",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "332",
              "code": "17486",
              "name": "Neira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "333",
              "code": "17495",
              "name": "Norcasia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "334",
              "code": "17513",
              "name": "Pácora",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "335",
              "code": "17524",
              "name": "Palestina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "336",
              "code": "17541",
              "name": "Pensilvania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "337",
              "code": "17614",
              "name": "Riosucio",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "338",
              "code": "17616",
              "name": "Risaralda",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "339",
              "code": "17653",
              "name": "Salamina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "340",
              "code": "17662",
              "name": "Samaná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "341",
              "code": "17665",
              "name": "San José ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "342",
              "code": "17777",
              "name": "Supía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "343",
              "code": "17867",
              "name": "Victoria",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "344",
              "code": "17873",
              "name": "Villamaría",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "345",
              "code": "17877",
              "name": "Viterbo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "346",
              "code": "18001",
              "name": "Florencia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "347",
              "code": "18029",
              "name": "Albania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "348",
              "code": "18094",
              "name": "Belén De Los Andaquíes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "349",
              "code": "18150",
              "name": "Cartagena Del Chairá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "350",
              "code": "18205",
              "name": "Curillo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "351",
              "code": "18247",
              "name": "El Doncello ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "352",
              "code": "18256",
              "name": "El Paujíl ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "353",
              "code": "18410",
              "name": "La Montañita ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "354",
              "code": "18460",
              "name": "Milán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "355",
              "code": "18479",
              "name": "Morelia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "356",
              "code": "18592",
              "name": "Puerto Rico ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "357",
              "code": "18610",
              "name": "San José Del Fragua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "358",
              "code": "18753",
              "name": "San Vicente Del Caguán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "359",
              "code": "18756",
              "name": "Solano",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "360",
              "code": "18785",
              "name": "Solita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "361",
              "code": "18860",
              "name": "Valparaíso",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "362",
              "code": "19001",
              "name": "Popayán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "363",
              "code": "19022",
              "name": "Almaguer",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "364",
              "code": "19050",
              "name": "Argelia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "365",
              "code": "19075",
              "name": "Balboa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "366",
              "code": "19100",
              "name": "Bolívar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "367",
              "code": "19110",
              "name": "Buenos Aires ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "368",
              "code": "19130",
              "name": "Cajibío",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "369",
              "code": "19137",
              "name": "Caldono",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "370",
              "code": "19142",
              "name": "Caloto",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "371",
              "code": "19212",
              "name": "Corinto",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "372",
              "code": "19256",
              "name": "El Tambo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "373",
              "code": "19290",
              "name": "Florencia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "374",
              "code": "19300",
              "name": "Guachené",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "375",
              "code": "19318",
              "name": "Guapí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "376",
              "code": "19355",
              "name": "Inzá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "377",
              "code": "19364",
              "name": "Jambaló",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "378",
              "code": "19392",
              "name": "La Sierra ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "379",
              "code": "19397",
              "name": "La Vega ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "380",
              "code": "19418",
              "name": "López De Micay",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "381",
              "code": "19450",
              "name": "Mercaderes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "382",
              "code": "19455",
              "name": "Miranda",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "383",
              "code": "19473",
              "name": "Morales",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "384",
              "code": "19513",
              "name": "Padilla",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "385",
              "code": "19517",
              "name": "Páez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "386",
              "code": "19532",
              "name": "Patía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "387",
              "code": "19533",
              "name": "Piamonte",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "388",
              "code": "19548",
              "name": "Piendamó - Tunía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "389",
              "code": "19573",
              "name": "Puerto Tejada ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "390",
              "code": "19585",
              "name": "Puracé",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "391",
              "code": "19622",
              "name": "Rosas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "392",
              "code": "19693",
              "name": "San Sebastián ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "393",
              "code": "19698",
              "name": "Santander De Quilichao",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "394",
              "code": "19701",
              "name": "Santa Rosa ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "395",
              "code": "19743",
              "name": "Silvia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "396",
              "code": "19760",
              "name": "Sotara",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "397",
              "code": "19780",
              "name": "Suárez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "398",
              "code": "19785",
              "name": "Sucre",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "399",
              "code": "19807",
              "name": "Timbío",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "400",
              "code": "19809",
              "name": "Timbiquí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "401",
              "code": "19821",
              "name": "Toribío",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "402",
              "code": "19824",
              "name": "Totoró",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "403",
              "code": "19845",
              "name": "Villa Rica ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "404",
              "code": "20001",
              "name": "Valledupar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "405",
              "code": "20011",
              "name": "Aguachica",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "406",
              "code": "20013",
              "name": "Agustín Codazzi ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "407",
              "code": "20032",
              "name": "Astrea",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "408",
              "code": "20045",
              "name": "Becerril",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "409",
              "code": "20060",
              "name": "Bosconia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "410",
              "code": "20175",
              "name": "Chimichagua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "411",
              "code": "20178",
              "name": "Chiriguaná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "412",
              "code": "20228",
              "name": "Curumaní",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "413",
              "code": "20238",
              "name": "El Copey ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "414",
              "code": "20250",
              "name": "El Paso ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "415",
              "code": "20295",
              "name": "Gamarra",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "416",
              "code": "20310",
              "name": "González",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "417",
              "code": "20383",
              "name": "La Gloria ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "418",
              "code": "20400",
              "name": "La Jagua De Ibirico",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "419",
              "code": "20443",
              "name": "Manaure Balcón Del Cesar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "420",
              "code": "20517",
              "name": "Pailitas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "421",
              "code": "20550",
              "name": "Pelaya",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "422",
              "code": "20570",
              "name": "Pueblo Bello ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "423",
              "code": "20614",
              "name": "Río De Oro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "424",
              "code": "20621",
              "name": "La Paz ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "425",
              "code": "20710",
              "name": "San Alberto ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "426",
              "code": "20750",
              "name": "San Diego ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "427",
              "code": "20770",
              "name": "San Martín ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "428",
              "code": "20787",
              "name": "Tamalameque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "429",
              "code": "23001",
              "name": "Montería",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "430",
              "code": "23068",
              "name": "Ayapel",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "431",
              "code": "23079",
              "name": "Buenavista",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "432",
              "code": "23090",
              "name": "Canalete",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "433",
              "code": "23162",
              "name": "Cereté",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "434",
              "code": "23168",
              "name": "Chimá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "435",
              "code": "23182",
              "name": "Chinú",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "436",
              "code": "23189",
              "name": "Ciénaga De Oro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "437",
              "code": "23300",
              "name": "Cotorra",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "438",
              "code": "23350",
              "name": "La Apartada ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "439",
              "code": "23417",
              "name": "Lorica",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "440",
              "code": "23419",
              "name": "Los Córdobas ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "441",
              "code": "23464",
              "name": "Momil",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "442",
              "code": "23466",
              "name": "Montelíbano",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "443",
              "code": "23500",
              "name": "Moñitos",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "444",
              "code": "23555",
              "name": "Planeta Rica ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "445",
              "code": "23570",
              "name": "Pueblo Nuevo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "446",
              "code": "23574",
              "name": "Puerto Escondido ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "447",
              "code": "23580",
              "name": "Puerto Libertador ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "448",
              "code": "23586",
              "name": "Purísima De La Concepción",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "449",
              "code": "23660",
              "name": "Sahagún",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "450",
              "code": "23670",
              "name": "San Andrés De Sotavento",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "451",
              "code": "23672",
              "name": "San Antero ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "452",
              "code": "23675",
              "name": "San Bernardo Del Viento",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "453",
              "code": "23678",
              "name": "San Carlos ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "454",
              "code": "23682",
              "name": "San José De Uré",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "455",
              "code": "23686",
              "name": "San Pelayo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "456",
              "code": "23807",
              "name": "Tierralta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "457",
              "code": "23815",
              "name": "Tuchín",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "458",
              "code": "23855",
              "name": "Valencia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "459",
              "code": "25001",
              "name": "Agua De Dios",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "460",
              "code": "25019",
              "name": "Albán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "461",
              "code": "25035",
              "name": "Anapoima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "462",
              "code": "25040",
              "name": "Anolaima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "463",
              "code": "25053",
              "name": "Arbeláez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "464",
              "code": "25086",
              "name": "Beltrán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "465",
              "code": "25095",
              "name": "Bituima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "466",
              "code": "25099",
              "name": "Bojacá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "467",
              "code": "25120",
              "name": "Cabrera",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "468",
              "code": "25123",
              "name": "Cachipay",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "469",
              "code": "25126",
              "name": "Cajicá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "470",
              "code": "25148",
              "name": "Caparrapí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "471",
              "code": "25151",
              "name": "Cáqueza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "472",
              "code": "25154",
              "name": "Carmen De Carupa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "473",
              "code": "25168",
              "name": "Chaguaní",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "474",
              "code": "25175",
              "name": "Chía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "475",
              "code": "25178",
              "name": "Chipaque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "476",
              "code": "25181",
              "name": "Choachí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "477",
              "code": "25183",
              "name": "Chocontá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "478",
              "code": "25200",
              "name": "Cogua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "479",
              "code": "25214",
              "name": "Cota",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "480",
              "code": "25224",
              "name": "Cucunubá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "481",
              "code": "25245",
              "name": "El Colegio ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "482",
              "code": "25258",
              "name": "El Peñón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "483",
              "code": "25260",
              "name": "El Rosal ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "484",
              "code": "25269",
              "name": "Facatativá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "485",
              "code": "25279",
              "name": "Fómeque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "486",
              "code": "25281",
              "name": "Fosca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "487",
              "code": "25286",
              "name": "Funza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "488",
              "code": "25288",
              "name": "Fúquene",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "489",
              "code": "25290",
              "name": "Fusagasugá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "490",
              "code": "25293",
              "name": "Gachalá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "491",
              "code": "25295",
              "name": "Gachancipá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "492",
              "code": "25297",
              "name": "Gachetá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "493",
              "code": "25299",
              "name": "Gama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "494",
              "code": "25307",
              "name": "Girardot",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "495",
              "code": "25312",
              "name": "Granada",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "496",
              "code": "25317",
              "name": "Guachetá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "497",
              "code": "25320",
              "name": "Guaduas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "498",
              "code": "25322",
              "name": "Guasca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "499",
              "code": "25324",
              "name": "Guataquí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "500",
              "code": "25326",
              "name": "Guatavita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "501",
              "code": "25328",
              "name": "Guayabal De Síquima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "502",
              "code": "25335",
              "name": "Guayabetal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "503",
              "code": "25339",
              "name": "Gutiérrez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "504",
              "code": "25368",
              "name": "Jerusalén",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "505",
              "code": "25372",
              "name": "Junín",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "506",
              "code": "25377",
              "name": "La Calera ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "507",
              "code": "25386",
              "name": "La Mesa ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "508",
              "code": "25394",
              "name": "La Palma ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "509",
              "code": "25398",
              "name": "La Peña ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "510",
              "code": "25402",
              "name": "La Vega ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "511",
              "code": "25407",
              "name": "Lenguazaque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "512",
              "code": "25426",
              "name": "Machetá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "513",
              "code": "25430",
              "name": "Madrid",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "514",
              "code": "25436",
              "name": "Manta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "515",
              "code": "25438",
              "name": "Medina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "516",
              "code": "25473",
              "name": "Mosquera",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "517",
              "code": "25483",
              "name": "Nariño",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "518",
              "code": "25486",
              "name": "Nemocón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "519",
              "code": "25488",
              "name": "Nilo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "520",
              "code": "25489",
              "name": "Nimaima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "521",
              "code": "25491",
              "name": "Nocaima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "522",
              "code": "25506",
              "name": "Venecia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "523",
              "code": "25513",
              "name": "Pacho",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "524",
              "code": "25518",
              "name": "Paime",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "525",
              "code": "25524",
              "name": "Pandi",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "526",
              "code": "25530",
              "name": "Paratebueno",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "527",
              "code": "25535",
              "name": "Pasca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "528",
              "code": "25572",
              "name": "Puerto Salgar ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "529",
              "code": "25580",
              "name": "Pulí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "530",
              "code": "25592",
              "name": "Quebradanegra",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "531",
              "code": "25594",
              "name": "Quetame",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "532",
              "code": "25596",
              "name": "Quipile",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "533",
              "code": "25599",
              "name": "Apulo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "534",
              "code": "25612",
              "name": "Ricaurte",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "535",
              "code": "25645",
              "name": "San Antonio Del Tequendama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "536",
              "code": "25649",
              "name": "San Bernardo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "537",
              "code": "25653",
              "name": "San Cayetano ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "538",
              "code": "25658",
              "name": "San Francisco ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "539",
              "code": "25662",
              "name": "San Juan De Rioseco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "540",
              "code": "25718",
              "name": "Sasaima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "541",
              "code": "25736",
              "name": "Sesquilé",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "542",
              "code": "25740",
              "name": "Sibaté",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "543",
              "code": "25743",
              "name": "Silvania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "544",
              "code": "25745",
              "name": "Simijaca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "545",
              "code": "25754",
              "name": "Soacha",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "546",
              "code": "25758",
              "name": "Sopó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "547",
              "code": "25769",
              "name": "Subachoque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "548",
              "code": "25772",
              "name": "Suesca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "549",
              "code": "25777",
              "name": "Supatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "550",
              "code": "25779",
              "name": "Susa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "551",
              "code": "25781",
              "name": "Sutatausa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "552",
              "code": "25785",
              "name": "Tabio",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "553",
              "code": "25793",
              "name": "Tausa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "554",
              "code": "25797",
              "name": "Tena",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "555",
              "code": "25799",
              "name": "Tenjo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "556",
              "code": "25805",
              "name": "Tibacuy",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "557",
              "code": "25807",
              "name": "Tibirita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "558",
              "code": "25815",
              "name": "Tocaima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "559",
              "code": "25817",
              "name": "Tocancipá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "560",
              "code": "25823",
              "name": "Topaipí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "561",
              "code": "25839",
              "name": "Ubalá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "562",
              "code": "25841",
              "name": "Ubaque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "563",
              "code": "25843",
              "name": "Villa De San Diego De Ubaté",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "564",
              "code": "25845",
              "name": "Une",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "565",
              "code": "25851",
              "name": "Útica",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "566",
              "code": "25862",
              "name": "Vergara",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "567",
              "code": "25867",
              "name": "Vianí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "568",
              "code": "25871",
              "name": "Villagómez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "569",
              "code": "25873",
              "name": "Villapinzón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "570",
              "code": "25875",
              "name": "Villeta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "571",
              "code": "25878",
              "name": "Viotá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "572",
              "code": "25885",
              "name": "Yacopí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "573",
              "code": "25898",
              "name": "Zipacón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "574",
              "code": "25899",
              "name": "Zipaquirá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "575",
              "code": "27001",
              "name": "Quibdó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "576",
              "code": "27006",
              "name": "Acandí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "577",
              "code": "27025",
              "name": "Alto Baudó ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "578",
              "code": "27050",
              "name": "Atrato",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "579",
              "code": "27073",
              "name": "Bagadó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "580",
              "code": "27075",
              "name": "Bahía Solano ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "581",
              "code": "27077",
              "name": "Bajo Baudó ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "582",
              "code": "27099",
              "name": "Bojayá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "583",
              "code": "27135",
              "name": "El Cantón Del San Pablo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "584",
              "code": "27150",
              "name": "Carmen Del Darién",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "585",
              "code": "27160",
              "name": "Cértegui",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "586",
              "code": "27205",
              "name": "Condoto",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "587",
              "code": "27245",
              "name": "El Carmen De Atrato",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "588",
              "code": "27250",
              "name": "El Litoral Del San Juan ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "589",
              "code": "27361",
              "name": "Istmina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "590",
              "code": "27372",
              "name": "Juradó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "591",
              "code": "27413",
              "name": "Lloró",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "592",
              "code": "27425",
              "name": "Medio Atrato ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "593",
              "code": "27430",
              "name": "Medio Baudó ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "594",
              "code": "27450",
              "name": "Medio San Juan",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "595",
              "code": "27491",
              "name": "Nóvita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "596",
              "code": "27495",
              "name": "Nuquí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "597",
              "code": "27580",
              "name": "Río Iró ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "598",
              "code": "27600",
              "name": "Río Quito ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "599",
              "code": "27615",
              "name": "Riosucio",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "600",
              "code": "27660",
              "name": "San José Del Palmar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "601",
              "code": "27745",
              "name": "Sipí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "602",
              "code": "27787",
              "name": "Tadó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "603",
              "code": "27800",
              "name": "Unguía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "604",
              "code": "27810",
              "name": "Unión Panamericana ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "605",
              "code": "41001",
              "name": "Neiva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "606",
              "code": "41006",
              "name": "Acevedo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "607",
              "code": "41013",
              "name": "Agrado",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "608",
              "code": "41016",
              "name": "Aipe",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "609",
              "code": "41020",
              "name": "Algeciras",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "610",
              "code": "41026",
              "name": "Altamira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "611",
              "code": "41078",
              "name": "Baraya",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "612",
              "code": "41132",
              "name": "Campoalegre",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "613",
              "code": "41206",
              "name": "Colombia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "614",
              "code": "41244",
              "name": "Elías",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "615",
              "code": "41298",
              "name": "Garzón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "616",
              "code": "41306",
              "name": "Gigante",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "617",
              "code": "41319",
              "name": "Guadalupe",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "618",
              "code": "41349",
              "name": "Hobo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "619",
              "code": "41357",
              "name": "Íquira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "620",
              "code": "41359",
              "name": "Isnos",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "621",
              "code": "41378",
              "name": "La Argentina ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "622",
              "code": "41396",
              "name": "La Plata ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "623",
              "code": "41483",
              "name": "Nátaga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "624",
              "code": "41503",
              "name": "Oporapa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "625",
              "code": "41518",
              "name": "Paicol",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "626",
              "code": "41524",
              "name": "Palermo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "627",
              "code": "41530",
              "name": "Palestina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "628",
              "code": "41548",
              "name": "Pital",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "629",
              "code": "41551",
              "name": "Pitalito",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "630",
              "code": "41615",
              "name": "Rivera",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "631",
              "code": "41660",
              "name": "Saladoblanco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "632",
              "code": "41668",
              "name": "San Agustín ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "633",
              "code": "41676",
              "name": "Santa María ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "634",
              "code": "41770",
              "name": "Suaza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "635",
              "code": "41791",
              "name": "Tarqui",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "636",
              "code": "41797",
              "name": "Tesalia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "637",
              "code": "41799",
              "name": "Tello",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "638",
              "code": "41801",
              "name": "Teruel",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "639",
              "code": "41807",
              "name": "Timaná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "640",
              "code": "41872",
              "name": "Villavieja",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "641",
              "code": "41885",
              "name": "Yaguará",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "642",
              "code": "44001",
              "name": "Riohacha",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "643",
              "code": "44035",
              "name": "Albania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "644",
              "code": "44078",
              "name": "Barrancas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "645",
              "code": "44090",
              "name": "Dibulla",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "646",
              "code": "44098",
              "name": "Distracción",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "647",
              "code": "44110",
              "name": "El Molino ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "648",
              "code": "44279",
              "name": "Fonseca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "649",
              "code": "44378",
              "name": "Hatonuevo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "650",
              "code": "44420",
              "name": "La Jagua Del Pilar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "651",
              "code": "44430",
              "name": "Maicao",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "652",
              "code": "44560",
              "name": "Manaure",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "653",
              "code": "44650",
              "name": "San Juan Del Cesar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "654",
              "code": "44847",
              "name": "Uribia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "655",
              "code": "44855",
              "name": "Urumita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "656",
              "code": "44874",
              "name": "Villanueva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "657",
              "code": "47001",
              "name": "Santa Marta ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "658",
              "code": "47030",
              "name": "Algarrobo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "659",
              "code": "47053",
              "name": "Aracataca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "660",
              "code": "47058",
              "name": "Ariguaní",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "661",
              "code": "47161",
              "name": "Cerro De San Antonio",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "662",
              "code": "47170",
              "name": "Chivolo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "663",
              "code": "47189",
              "name": "Ciénaga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "664",
              "code": "47205",
              "name": "Concordia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "665",
              "code": "47245",
              "name": "El Banco ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "666",
              "code": "47258",
              "name": "El Piñón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "667",
              "code": "47268",
              "name": "El Retén ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "668",
              "code": "47288",
              "name": "Fundación",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "669",
              "code": "47318",
              "name": "Guamal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "670",
              "code": "47460",
              "name": "Nueva Granada ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "671",
              "code": "47541",
              "name": "Pedraza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "672",
              "code": "47545",
              "name": "Pijiño Del Carmen",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "673",
              "code": "47551",
              "name": "Pivijay",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "674",
              "code": "47555",
              "name": "Plato",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "675",
              "code": "47570",
              "name": "Puebloviejo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "676",
              "code": "47605",
              "name": "Remolino",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "677",
              "code": "47660",
              "name": "Sabanas De San Ángel",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "678",
              "code": "47675",
              "name": "Salamina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "679",
              "code": "47692",
              "name": "San Sebastián De Buenavista",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "680",
              "code": "47703",
              "name": "San Zenón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "681",
              "code": "47707",
              "name": "Santa Ana ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "682",
              "code": "47720",
              "name": "Santa Bárbara De Pinto",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "683",
              "code": "47745",
              "name": "Sitionuevo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "684",
              "code": "47798",
              "name": "Tenerife",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "685",
              "code": "47960",
              "name": "Zapayán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "686",
              "code": "47980",
              "name": "Zona Bananera ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "687",
              "code": "50001",
              "name": "Villavicencio",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "688",
              "code": "50006",
              "name": "Acacías",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "689",
              "code": "50110",
              "name": "Barranca De Upía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "690",
              "code": "50124",
              "name": "Cabuyaro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "691",
              "code": "50150",
              "name": "Castilla La Nueva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "692",
              "code": "50223",
              "name": "Cubarral",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "693",
              "code": "50226",
              "name": "Cumaral",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "694",
              "code": "50245",
              "name": "El Calvario ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "695",
              "code": "50251",
              "name": "El Castillo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "696",
              "code": "50270",
              "name": "El Dorado ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "697",
              "code": "50287",
              "name": "Fuente De Oro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "698",
              "code": "50313",
              "name": "Granada",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "699",
              "code": "50318",
              "name": "Guamal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "700",
              "code": "50325",
              "name": "Mapiripán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "701",
              "code": "50330",
              "name": "Mesetas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "702",
              "code": "50350",
              "name": "La Macarena ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "703",
              "code": "50370",
              "name": "Uribe",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "704",
              "code": "50400",
              "name": "Lejanías",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "705",
              "code": "50450",
              "name": "Puerto Concordia ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "706",
              "code": "50568",
              "name": "Puerto Gaitán ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "707",
              "code": "50573",
              "name": "Puerto López ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "708",
              "code": "50577",
              "name": "Puerto Lleras ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "709",
              "code": "50590",
              "name": "Puerto Rico ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "710",
              "code": "50606",
              "name": "Restrepo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "711",
              "code": "50680",
              "name": "San Carlos De Guaroa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "712",
              "code": "50683",
              "name": "San Juan De Arama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "713",
              "code": "50686",
              "name": "San Juanito ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "714",
              "code": "50689",
              "name": "San Martín ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "715",
              "code": "50711",
              "name": "Vistahermosa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
              
          },
          {
              "tableName": "municipalities",
              "id": "716",
              "code": "52001",
              "name": "Pasto",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
          },
          {
              "tableName": "municipalities",
              "id": "717",
              "code": "52019",
              "name": "Albán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "718",
              "code": "52022",
              "name": "Aldana",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "719",
              "code": "52036",
              "name": "Ancuyá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "720",
              "code": "52051",
              "name": "Arboleda",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "721",
              "code": "52079",
              "name": "Barbacoas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "722",
              "code": "52083",
              "name": "Belén",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "723",
              "code": "52110",
              "name": "Buesaco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "724",
              "code": "52203",
              "name": "Colón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "725",
              "code": "52207",
              "name": "Consacá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "726",
              "code": "52210",
              "name": "Contadero",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "727",
              "code": "52215",
              "name": "Córdoba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "728",
              "code": "52224",
              "name": "Cuaspúd",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "729",
              "code": "52227",
              "name": "Cumbal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "730",
              "code": "52233",
              "name": "Cumbitara",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "731",
              "code": "52240",
              "name": "Chachagüí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "732",
              "code": "52250",
              "name": "El Charco ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "733",
              "code": "52254",
              "name": "El Peñol ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "734",
              "code": "52256",
              "name": "El Rosario ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "735",
              "code": "52258",
              "name": "El Tablón De Gómez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "736",
              "code": "52260",
              "name": "El Tambo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "737",
              "code": "52287",
              "name": "Funes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "738",
              "code": "52317",
              "name": "Guachucal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "739",
              "code": "52320",
              "name": "Guaitarilla",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "740",
              "code": "52323",
              "name": "Gualmatán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "741",
              "code": "52352",
              "name": "Iles",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "742",
              "code": "52354",
              "name": "Imués",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "743",
              "code": "52356",
              "name": "Ipiales",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "744",
              "code": "52378",
              "name": "La Cruz ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "745",
              "code": "52381",
              "name": "La Florida ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "746",
              "code": "52385",
              "name": "La Llanada ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "747",
              "code": "52390",
              "name": "La Tola ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "748",
              "code": "52399",
              "name": "La Unión ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "749",
              "code": "52405",
              "name": "Leiva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "750",
              "code": "52411",
              "name": "Linares",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "751",
              "code": "52418",
              "name": "Los Andes ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "752",
              "code": "52427",
              "name": "Magüí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "753",
              "code": "52435",
              "name": "Mallama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "754",
              "code": "52473",
              "name": "Mosquera",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "755",
              "code": "52480",
              "name": "Nariño",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "756",
              "code": "52490",
              "name": "Olaya Herrera ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "757",
              "code": "52506",
              "name": "Ospina",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "758",
              "code": "52520",
              "name": "Francisco Pizarro ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "759",
              "code": "52540",
              "name": "Policarpa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "760",
              "code": "52560",
              "name": "Potosí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "761",
              "code": "52565",
              "name": "Providencia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "762",
              "code": "52573",
              "name": "Puerres",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "763",
              "code": "52585",
              "name": "Pupiales",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "764",
              "code": "52612",
              "name": "Ricaurte",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "765",
              "code": "52621",
              "name": "Roberto Payán ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "766",
              "code": "52678",
              "name": "Samaniego",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "767",
              "code": "52683",
              "name": "Sandoná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "768",
              "code": "52685",
              "name": "San Bernardo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "769",
              "code": "52687",
              "name": "San Lorenzo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "770",
              "code": "52693",
              "name": "San Pablo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "771",
              "code": "52694",
              "name": "San Pedro De Cartago",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "772",
              "code": "52696",
              "name": "Santa Bárbara ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "773",
              "code": "52699",
              "name": "Santacruz",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "774",
              "code": "52720",
              "name": "Sapuyes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "775",
              "code": "52786",
              "name": "Taminango",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "776",
              "code": "52788",
              "name": "Tangua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "777",
              "code": "52835",
              "name": "San Andrés De Tumaco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "778",
              "code": "52838",
              "name": "Túquerres",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "779",
              "code": "52885",
              "name": "Yacuanquer",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "780",
              "code": "54001",
              "name": "San José De Cúcuta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "781",
              "code": "54003",
              "name": "Ábrego",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "782",
              "code": "54051",
              "name": "Arboledas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "783",
              "code": "54099",
              "name": "Bochalema",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "784",
              "code": "54109",
              "name": "Bucarasica",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "785",
              "code": "54125",
              "name": "Cácota",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "786",
              "code": "54128",
              "name": "Cáchira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "787",
              "code": "54172",
              "name": "Chinácota",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "788",
              "code": "54174",
              "name": "Chitagá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "789",
              "code": "54206",
              "name": "Convención",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "790",
              "code": "54223",
              "name": "Cucutilla",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "791",
              "code": "54239",
              "name": "Durania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "792",
              "code": "54245",
              "name": "El Carmen ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "793",
              "code": "54250",
              "name": "El Tarra ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "794",
              "code": "54261",
              "name": "El Zulia ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "795",
              "code": "54313",
              "name": "Gramalote",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "796",
              "code": "54344",
              "name": "Hacarí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "797",
              "code": "54347",
              "name": "Herrán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "798",
              "code": "54377",
              "name": "Labateca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "799",
              "code": "54385",
              "name": "La Esperanza ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "800",
              "code": "54398",
              "name": "La Playa ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "801",
              "code": "54405",
              "name": "Los Patios ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "802",
              "code": "54418",
              "name": "Lourdes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "803",
              "code": "54480",
              "name": "Mutiscua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "804",
              "code": "54498",
              "name": "Ocaña",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "805",
              "code": "54518",
              "name": "Pamplona",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "806",
              "code": "54520",
              "name": "Pamplonita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "807",
              "code": "54553",
              "name": "Puerto Santander ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "808",
              "code": "54599",
              "name": "Ragonvalia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "809",
              "code": "54660",
              "name": "Salazar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "810",
              "code": "54670",
              "name": "San Calixto ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "811",
              "code": "54673",
              "name": "San Cayetano ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "812",
              "code": "54680",
              "name": "Santiago",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "813",
              "code": "54720",
              "name": "Sardinata",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "814",
              "code": "54743",
              "name": "Silos",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "815",
              "code": "54800",
              "name": "Teorama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "816",
              "code": "54810",
              "name": "Tibú",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "817",
              "code": "54820",
              "name": "Toledo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "818",
              "code": "54871",
              "name": "Villa Caro ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "819",
              "code": "54874",
              "name": "Villa Del Rosario",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "820",
              "code": "63001",
              "name": "Armenia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "821",
              "code": "63111",
              "name": "Buenavista",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "822",
              "code": "63130",
              "name": "Calarcá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "823",
              "code": "63190",
              "name": "Circasia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "824",
              "code": "63212",
              "name": "Córdoba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "825",
              "code": "63272",
              "name": "Filandia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "826",
              "code": "63302",
              "name": "Génova",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "827",
              "code": "63401",
              "name": "La Tebaida ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "828",
              "code": "63470",
              "name": "Montenegro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "829",
              "code": "63548",
              "name": "Pijao",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "830",
              "code": "63594",
              "name": "Quimbaya",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "831",
              "code": "63690",
              "name": "Salento",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "832",
              "code": "66001",
              "name": "Pereira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "833",
              "code": "66045",
              "name": "Apía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "834",
              "code": "66075",
              "name": "Balboa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "835",
              "code": "66088",
              "name": "Belén De Umbría",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "836",
              "code": "66170",
              "name": "Dosquebradas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "837",
              "code": "66318",
              "name": "Guática",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "838",
              "code": "66383",
              "name": "La Celia ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "839",
              "code": "66400",
              "name": "La Virginia ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "840",
              "code": "66440",
              "name": "Marsella",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "841",
              "code": "66456",
              "name": "Mistrató",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "842",
              "code": "66572",
              "name": "Pueblo Rico ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "843",
              "code": "66594",
              "name": "Quinchía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "844",
              "code": "66682",
              "name": "Santa Rosa De Cabal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "845",
              "code": "66687",
              "name": "Santuario",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "846",
              "code": "68001",
              "name": "Bucaramanga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "847",
              "code": "68013",
              "name": "Aguada",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "848",
              "code": "68020",
              "name": "Albania",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "849",
              "code": "68051",
              "name": "Aratoca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "850",
              "code": "68077",
              "name": "Barbosa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "851",
              "code": "68079",
              "name": "Barichara",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "852",
              "code": "68081",
              "name": "Barrancabermeja",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "853",
              "code": "68092",
              "name": "Betulia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "854",
              "code": "68101",
              "name": "Bolívar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "855",
              "code": "68121",
              "name": "Cabrera",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "856",
              "code": "68132",
              "name": "California",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "857",
              "code": "68147",
              "name": "Capitanejo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "858",
              "code": "68152",
              "name": "Carcasí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "859",
              "code": "68160",
              "name": "Cepitá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "860",
              "code": "68162",
              "name": "Cerrito",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "861",
              "code": "68167",
              "name": "Charalá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "862",
              "code": "68169",
              "name": "Charta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "863",
              "code": "68176",
              "name": "Chima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "864",
              "code": "68179",
              "name": "Chipatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "865",
              "code": "68190",
              "name": "Cimitarra",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "866",
              "code": "68207",
              "name": "Concepción",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "867",
              "code": "68209",
              "name": "Confines",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "868",
              "code": "68211",
              "name": "Contratación",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "869",
              "code": "68217",
              "name": "Coromoro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "870",
              "code": "68229",
              "name": "Curití",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "871",
              "code": "68235",
              "name": "El Carmen De Chucurí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "872",
              "code": "68245",
              "name": "El Guacamayo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "873",
              "code": "68250",
              "name": "El Peñón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "874",
              "code": "68255",
              "name": "El Playón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "875",
              "code": "68264",
              "name": "Encino",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "876",
              "code": "68266",
              "name": "Enciso",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "877",
              "code": "68271",
              "name": "Florián",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "878",
              "code": "68276",
              "name": "Floridablanca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "879",
              "code": "68296",
              "name": "Galán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "880",
              "code": "68298",
              "name": "Gámbita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "881",
              "code": "68307",
              "name": "Girón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "882",
              "code": "68318",
              "name": "Guaca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "883",
              "code": "68320",
              "name": "Guadalupe",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "884",
              "code": "68322",
              "name": "Guapotá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "885",
              "code": "68324",
              "name": "Guavatá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "886",
              "code": "68327",
              "name": "Güepsa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "887",
              "code": "68344",
              "name": "Hato",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "888",
              "code": "68368",
              "name": "Jesús María ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "889",
              "code": "68370",
              "name": "Jordán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "890",
              "code": "68377",
              "name": "La Belleza ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "891",
              "code": "68385",
              "name": "Landázuri",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "892",
              "code": "68397",
              "name": "La Paz ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "893",
              "code": "68406",
              "name": "Lebrija",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "894",
              "code": "68418",
              "name": "Los Santos ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "895",
              "code": "68425",
              "name": "Macaravita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "896",
              "code": "68432",
              "name": "Málaga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "897",
              "code": "68444",
              "name": "Matanza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "898",
              "code": "68464",
              "name": "Mogotes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "899",
              "code": "68468",
              "name": "Molagavita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "900",
              "code": "68498",
              "name": "Ocamonte",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "901",
              "code": "68500",
              "name": "Oiba",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "902",
              "code": "68502",
              "name": "Onzaga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "903",
              "code": "68522",
              "name": "Palmar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "904",
              "code": "68524",
              "name": "Palmas Del Socorro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "905",
              "code": "68533",
              "name": "Páramo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "906",
              "code": "68547",
              "name": "Piedecuesta",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "907",
              "code": "68549",
              "name": "Pinchote",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "908",
              "code": "68572",
              "name": "Puente Nacional ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "909",
              "code": "68573",
              "name": "Puerto Parra ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "910",
              "code": "68575",
              "name": "Puerto Wilches ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "911",
              "code": "68615",
              "name": "Rionegro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "912",
              "code": "68655",
              "name": "Sabana De Torres",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "913",
              "code": "68669",
              "name": "San Andrés ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "914",
              "code": "68673",
              "name": "San Benito ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "915",
              "code": "68679",
              "name": "San Gil ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "916",
              "code": "68682",
              "name": "San Joaquín ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "917",
              "code": "68684",
              "name": "San José De Miranda",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "918",
              "code": "68686",
              "name": "San Miguel ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "919",
              "code": "68689",
              "name": "San Vicente De Chucurí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "920",
              "code": "68705",
              "name": "Santa Bárbara ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "921",
              "code": "68720",
              "name": "Santa Helena Del Opón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "922",
              "code": "68745",
              "name": "Simacota",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "923",
              "code": "68755",
              "name": "Socorro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "924",
              "code": "68770",
              "name": "Suaita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "925",
              "code": "68773",
              "name": "Sucre",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "926",
              "code": "68780",
              "name": "Suratá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "927",
              "code": "68820",
              "name": "Tona",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "928",
              "code": "68855",
              "name": "Valle De San José",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "929",
              "code": "68861",
              "name": "Vélez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "930",
              "code": "68867",
              "name": "Vetas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "931",
              "code": "68872",
              "name": "Villanueva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "932",
              "code": "68895",
              "name": "Zapatoca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "933",
              "code": "70001",
              "name": "Sincelejo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "934",
              "code": "70110",
              "name": "Buenavista",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "935",
              "code": "70124",
              "name": "Caimito",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "936",
              "code": "70204",
              "name": "Colosó",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "937",
              "code": "70215",
              "name": "Corozal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "938",
              "code": "70221",
              "name": "Coveñas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "939",
              "code": "70230",
              "name": "Chalán",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "940",
              "code": "70233",
              "name": "El Roble ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "941",
              "code": "70235",
              "name": "Galeras",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "942",
              "code": "70265",
              "name": "Guaranda",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "943",
              "code": "70400",
              "name": "La Unión ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "944",
              "code": "70418",
              "name": "Los Palmitos ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "945",
              "code": "70429",
              "name": "Majagual",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "946",
              "code": "70473",
              "name": "Morroa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "947",
              "code": "70508",
              "name": "Ovejas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "948",
              "code": "70523",
              "name": "Palmito",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "949",
              "code": "70670",
              "name": "Sampués",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "950",
              "code": "70678",
              "name": "San Benito Abad",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "951",
              "code": "70702",
              "name": "San Juan De Betulia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "952",
              "code": "70708",
              "name": "San Marcos ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "953",
              "code": "70713",
              "name": "San Onofre ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "954",
              "code": "70717",
              "name": "San Pedro ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "955",
              "code": "70742",
              "name": "San Luis De Sincé",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "956",
              "code": "70771",
              "name": "Sucre",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "957",
              "code": "70820",
              "name": "Santiago De Tolú",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "958",
              "code": "70823",
              "name": "Tolú Viejo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "959",
              "code": "73001",
              "name": "Ibagué",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "960",
              "code": "73024",
              "name": "Alpujarra",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "961",
              "code": "73026",
              "name": "Alvarado",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "962",
              "code": "73030",
              "name": "Ambalema",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "963",
              "code": "73043",
              "name": "Anzoátegui",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "964",
              "code": "73055",
              "name": "Armero",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "965",
              "code": "73067",
              "name": "Ataco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "966",
              "code": "73124",
              "name": "Cajamarca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "967",
              "code": "73148",
              "name": "Carmen De Apicalá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "968",
              "code": "73152",
              "name": "Casabianca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "969",
              "code": "73168",
              "name": "Chaparral",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "970",
              "code": "73200",
              "name": "Coello",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "971",
              "code": "73217",
              "name": "Coyaima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "972",
              "code": "73226",
              "name": "Cunday",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "973",
              "code": "73236",
              "name": "Dolores",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "974",
              "code": "73268",
              "name": "Espinal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "975",
              "code": "73270",
              "name": "Falan",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "976",
              "code": "73275",
              "name": "Flandes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "977",
              "code": "73283",
              "name": "Fresno",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "978",
              "code": "73319",
              "name": "Guamo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "979",
              "code": "73347",
              "name": "Herveo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "980",
              "code": "73349",
              "name": "Honda",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "981",
              "code": "73352",
              "name": "Icononzo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "982",
              "code": "73408",
              "name": "Lérida",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "983",
              "code": "73411",
              "name": "Líbano",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "984",
              "code": "73443",
              "name": "San Sebastián De Mariquita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "985",
              "code": "73449",
              "name": "Melgar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "986",
              "code": "73461",
              "name": "Murillo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "987",
              "code": "73483",
              "name": "Natagaima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "988",
              "code": "73504",
              "name": "Ortega",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "989",
              "code": "73520",
              "name": "Palocabildo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "990",
              "code": "73547",
              "name": "Piedras",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "991",
              "code": "73555",
              "name": "Planadas",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "992",
              "code": "73563",
              "name": "Prado",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "993",
              "code": "73585",
              "name": "Purificación",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "994",
              "code": "73616",
              "name": "Rioblanco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "995",
              "code": "73622",
              "name": "Roncesvalles",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "996",
              "code": "73624",
              "name": "Rovira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "997",
              "code": "73671",
              "name": "Saldaña",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "998",
              "code": "73675",
              "name": "San Antonio ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "999",
              "code": "73678",
              "name": "San Luis ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1000",
              "code": "73686",
              "name": "Santa Isabel ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1001",
              "code": "73770",
              "name": "Suárez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1002",
              "code": "73854",
              "name": "Valle De San Juan",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1003",
              "code": "73861",
              "name": "Venadillo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1004",
              "code": "73870",
              "name": "Villahermosa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1005",
              "code": "73873",
              "name": "Villarrica",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1006",
              "code": "76001",
              "name": "Cali",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1007",
              "code": "76020",
              "name": "Alcalá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1008",
              "code": "76036",
              "name": "Andalucía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1009",
              "code": "76041",
              "name": "Ansermanuevo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1010",
              "code": "76054",
              "name": "Argelia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1011",
              "code": "76100",
              "name": "Bolívar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1012",
              "code": "76109",
              "name": "Buenaventura",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1013",
              "code": "76111",
              "name": "Guadalajara De Buga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1014",
              "code": "76113",
              "name": "Bugalagrande",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1015",
              "code": "76122",
              "name": "Caicedonia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1016",
              "code": "76126",
              "name": "Calima",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1017",
              "code": "76130",
              "name": "Candelaria",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1018",
              "code": "76147",
              "name": "Cartago",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1019",
              "code": "76233",
              "name": "Dagua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1020",
              "code": "76243",
              "name": "El Águila ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1021",
              "code": "76246",
              "name": "El Cairo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1022",
              "code": "76248",
              "name": "El Cerrito ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1023",
              "code": "76250",
              "name": "El Dovio ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1024",
              "code": "76275",
              "name": "Florida",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1025",
              "code": "76306",
              "name": "Ginebra",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1026",
              "code": "76318",
              "name": "Guacarí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1027",
              "code": "76364",
              "name": "Jamundí",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1028",
              "code": "76377",
              "name": "La Cumbre ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1029",
              "code": "76400",
              "name": "La Unión ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1030",
              "code": "76403",
              "name": "La Victoria ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1031",
              "code": "76497",
              "name": "Obando",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1032",
              "code": "76520",
              "name": "Palmira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1033",
              "code": "76563",
              "name": "Pradera",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1034",
              "code": "76606",
              "name": "Restrepo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1035",
              "code": "76616",
              "name": "Riofrío",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1036",
              "code": "76622",
              "name": "Roldanillo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1037",
              "code": "76670",
              "name": "San Pedro ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1038",
              "code": "76736",
              "name": "Sevilla",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1039",
              "code": "76823",
              "name": "Toro",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1040",
              "code": "76828",
              "name": "Trujillo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1041",
              "code": "76834",
              "name": "Tuluá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1042",
              "code": "76845",
              "name": "Ulloa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1043",
              "code": "76863",
              "name": "Versalles",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1044",
              "code": "76869",
              "name": "Vijes",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1045",
              "code": "76890",
              "name": "Yotoco",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1046",
              "code": "76892",
              "name": "Yumbo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1047",
              "code": "76895",
              "name": "Zarzal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1048",
              "code": "81001",
              "name": "Arauca",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1049",
              "code": "81065",
              "name": "Arauquita",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1050",
              "code": "81220",
              "name": "Cravo Norte ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1051",
              "code": "81300",
              "name": "Fortul",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1052",
              "code": "81591",
              "name": "Puerto Rondón ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1053",
              "code": "81736",
              "name": "Saravena",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1054",
              "code": "81794",
              "name": "Tame",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1055",
              "code": "85001",
              "name": "Yopal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1056",
              "code": "85010",
              "name": "Aguazul",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1057",
              "code": "85015",
              "name": "Chámeza",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1058",
              "code": "85125",
              "name": "Hato Corozal ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1059",
              "code": "85136",
              "name": "La Salina ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1060",
              "code": "85139",
              "name": "Maní",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1061",
              "code": "85162",
              "name": "Monterrey",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1062",
              "code": "85225",
              "name": "Nunchía",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1063",
              "code": "85230",
              "name": "Orocué",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1064",
              "code": "85250",
              "name": "Paz De Ariporo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1065",
              "code": "85263",
              "name": "Pore",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1066",
              "code": "85279",
              "name": "Recetor",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1067",
              "code": "85300",
              "name": "Sabanalarga",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1068",
              "code": "85315",
              "name": "Sácama",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1069",
              "code": "85325",
              "name": "San Luis De Palenque",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1070",
              "code": "85400",
              "name": "Támara",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1071",
              "code": "85410",
              "name": "Tauramena",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1072",
              "code": "85430",
              "name": "Trinidad",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1073",
              "code": "85440",
              "name": "Villanueva",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1074",
              "code": "86001",
              "name": "Mocoa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1075",
              "code": "86219",
              "name": "Colón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1076",
              "code": "86320",
              "name": "Orito",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1077",
              "code": "86568",
              "name": "Puerto Asís ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1078",
              "code": "86569",
              "name": "Puerto Caicedo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1079",
              "code": "86571",
              "name": "Puerto Guzmán ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1080",
              "code": "86573",
              "name": "Puerto Leguízamo ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1081",
              "code": "86749",
              "name": "Sibundoy",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1082",
              "code": "86755",
              "name": "San Francisco ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1083",
              "code": "86757",
              "name": "San Miguel ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1084",
              "code": "86760",
              "name": "Santiago",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1085",
              "code": "86865",
              "name": "Valle Del Guamuez",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1086",
              "code": "86885",
              "name": "Villagarzón",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1087",
              "code": "88001",
              "name": "San Andrés ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1088",
              "code": "88564",
              "name": "Providencia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1089",
              "code": "91001",
              "name": "Leticia",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1090",
              "code": "91263",
              "name": "El Encanto ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1091",
              "code": "91405",
              "name": "La Chorrera ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1092",
              "code": "91407",
              "name": "La Pedrera ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1093",
              "code": "91430",
              "name": "La Victoria ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1094",
              "code": "91460",
              "name": "Mirití - Paraná",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1095",
              "code": "91530",
              "name": "Puerto Alegría ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1096",
              "code": "91536",
              "name": "Puerto Arica ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1097",
              "code": "91540",
              "name": "Puerto Nariño ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1098",
              "code": "91669",
              "name": "Puerto Santander ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1099",
              "code": "91798",
              "name": "Tarapacá",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1100",
              "code": "94001",
              "name": "Inírida",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1101",
              "code": "94343",
              "name": "Barranco Minas ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1102",
              "code": "94663",
              "name": "Mapiripana",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1103",
              "code": "94883",
              "name": "San Felipe ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1104",
              "code": "94884",
              "name": "Puerto Colombia ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1105",
              "code": "94885",
              "name": "La Guadalupe ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1106",
              "code": "94886",
              "name": "Cacahual",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1107",
              "code": "94887",
              "name": "Pana Pana ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1108",
              "code": "94888",
              "name": "Morichal",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1109",
              "code": "95001",
              "name": "San José Del Guaviare",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1110",
              "code": "95015",
              "name": "Calamar",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1111",
              "code": "95025",
              "name": "El Retorno ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1112",
              "code": "95200",
              "name": "Miraflores",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1113",
              "code": "97001",
              "name": "Mitú",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1114",
              "code": "97161",
              "name": "Carurú",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1115",
              "code": "97511",
              "name": "Pacoa",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1116",
              "code": "97666",
              "name": "Taraira",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1117",
              "code": "97777",
              "name": "Papunahua",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1118",
              "code": "97889",
              "name": "Yavaraté",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1119",
              "code": "99001",
              "name": "Puerto Carreño ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1120",
              "code": "99524",
              "name": "La Primavera ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1121",
              "code": "99624",
              "name": "Santa Rosalía ",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          },
          {
              "tableName": "municipalities",
              "id": "1122",
              "code": "99773",
              "name": "Cumaribo",
              "description": null,
              "deleted_at": "",
              "created_at": "2022-09-20T15:29:45.000000Z",
              "updated_at": "2022-09-20T15:29:45.000000Z",
              "scope": null,
             
             
             
              
              
          }
      ],
      "departments": [
        {
          "id": "1",
          "name": "Amazonas",
          "code": "91",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "2",
          "name": "Antioquia",
          "code": "05",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "3",
          "name": "Arauca",
          "code": "81",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "4",
          "name": "Atlántico",
          "code": "08",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "5",
          "name": "Bogotá",
          "code": "11",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "6",
          "name": "Bolívar",
          "code": "13",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "7",
          "name": "Boyacá",
          "code": "15",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "8",
          "name": "Caldas",
          "code": "17",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "9",
          "name": "Caquetá",
          "code": "18",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "10",
          "name": "Casanare",
          "code": "85",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "11",
          "name": "Cauca",
          "code": "19",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "12",
          "name": "Cesar",
          "code": "20",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "13",
          "name": "Chocó",
          "code": "27",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "14",
          "name": "Córdoba",
          "code": "23",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "15",
          "name": "Cundinamarca",
          "code": "25",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "16",
          "name": "Guainía",
          "code": "94",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "17",
          "name": "Guaviare",
          "code": "95",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "18",
          "name": "Huila",
          "code": "41",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "19",
          "name": "La Guajira",
          "code": "44",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "20",
          "name": "Magdalena",
          "code": "47",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "21",
          "name": "Meta",
          "code": "50",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "22",
          "name": "Nariño",
          "code": "52",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "23",
          "name": "Norte de Santander",
          "code": "54",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "24",
          "name": "Putumayo",
          "code": "86",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "25",
          "name": "Quindío",
          "code": "63",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "26",
          "name": "Risaralda",
          "code": "66",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "27",
          "name": "San Andrés y Providencia",
          "code": "88",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "28",
          "name": "Santander",
          "code": "68",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "29",
          "name": "Sucre",
          "code": "70",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "30",
          "name": "Tolima",
          "code": "73",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "31",
          "name": "Valle del Cauca",
          "code": "76",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "32",
          "name": "Vaupés",
          "code": "97",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        },
        {
          "id": "33",
          "name": "Vichada",
          "code": "99",
          "deleted_at": null,
          "created_at": "2023-10-20T16:09:17.000000Z",
          "updated_at": "2023-10-20T16:09:17.000000Z"
        }
      ],
      
      "type_environments":[
        {
            "tableName": "type_environments",
            "id": "1",
            "code": "1",
            "name": "Producción",
            "description": null,
            "deleted_at": "",
            "created_at": "2022-09-20T15:29:45.000000Z",
            "updated_at": "2022-09-20T15:29:45.000000Z",
            "scope": null,
            "cufe_algorithm": null,
            "type": null,
            "prefix": null
        },
        {
            "tableName": "type_environments",
            "id": "2",
            "code": "2",
            "name": "Pruebas",
            "description": null,
            "deleted_at": "",
            "created_at": "2022-09-20T15:29:45.000000Z",
            "updated_at": "2022-09-20T15:29:45.000000Z",
            "scope": null,
            "cufe_algorithm": null,
            "type": null,
            "prefix": null
        }
      ]
      ,
        ...data
    }
}