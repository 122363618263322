export default (data = {}) => {
    return {
        document: {
            doc_number: null,
            doc_value: null,
            id_user: null,
            invoice: null,
            issueDate: null,
            saler: null,
            store: null,
            vendor: null,
            doc_reference:null,
            doc_type: "pos"
        },
        buyer: {
            PartyIdentification: null,
            PartyName: null,
            PartyEmail:null,
            client_email: null,
            client_phone: null,
        },
        permit: {
            Actual: null,
            AuthorizationFrom: null,
            AuthorizationTo: null,
            EndDate: null,
            InvoiceAuthorization: null,
            Prefix: null,
            SoftwareSecurityCode: null,
            StartDate: null,
            documentType: null,
            store: null,
        },
        items: [],
        payments: [],
        seller: {
            AddressLine: null,
            CityName: null,
            PartyIdentification: null,
            PartyIdentification_Id: null,
            PartyName: null,
            TaxLevelCode: null,
            client_email: null,
            client_phone: null,
        },
        ...data
    }
}